import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageableResponse } from "@pwc-ecobonus/common";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { FaqCategory } from "../models/faq-category";

@Injectable({
  providedIn: 'root'
})
export class FaqCategoryService {
  protected faqEndpoint: string = "faq";
  protected faqCategoryEndpoint: string = "category";


  constructor(private http: HttpClient) { }

  public search(categoryResource: any) {
    return this.http.get(`${environment.apiUrl}/${this.faqEndpoint}/${this.faqCategoryEndpoint}`, { params: categoryResource });
  }
  public detail(id: number) {

    return this.http.get(encodeURI(`${environment.apiUrl}/${this.faqEndpoint}/${this.faqCategoryEndpoint}/${id}`));
  }

  public update(category: FaqCategory) {
    return this.http.put(`${environment.apiUrl}/${this.faqEndpoint}/${this.faqCategoryEndpoint}`, category);
  }

  public insert(category: FaqCategory) {
    return this.http.post(`${environment.apiUrl}/${this.faqEndpoint}/${this.faqCategoryEndpoint}`, category);
  }

  public deleteCategory(id: number) {
    return this.http.delete(`${environment.apiUrl}/${this.faqEndpoint}/${this.faqCategoryEndpoint}/${id}`);
  }

  public getCategories(maxAmount: number): Observable<any> {
    const searchFilters = {
      page: 0,
      size: maxAmount
    };
    var observable = new Subject<any>();
    this.search(searchFilters).subscribe(
      (response: PageableResponse<FaqCategory[]>) => {
        let options = response.content.map((category: FaqCategory) => ({
          id: category.id,
          name: category.name,
          label: category.name
        }));
        observable.next(options);
      }
    )
    return observable.asObservable();
  }
}
